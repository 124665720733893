import React from "react";
import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";
import { Container, Section } from "../components/global";
import Footer from "../components/sections/footer";



const Careers = () => (
  <Layout>
    <SEO
      title="Ranger Careers"
      description="Job openings at Ranger, the no-code solution for connecting Excel data to the cloud."
    />
    <Navigation />
    <Container>
      <Section>
        <h1>Careers</h1>
        <h4>
          We don't have any positions open right now, but check back soon.
        </h4>
      </Section>
    </Container>
    <Footer />
  </Layout>
);

export default Careers;
